// components/GetStartedFloating.tsx
'use client';
import { useState } from 'react';
import Link from 'next/link';
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="get-started-floating">
      <ul>
        <li>
          <a
            className="help-link"
            id="gs-help-link"
            onClick={() => setIsOpen(!isOpen)}
          >
            Get Started
          </a>
          <div
            className={`find-course ${isOpen ? 'show' : ''}`}
            id="gs-find-course"
          >
            <div className="title">Find the right course for you</div>
            <div className="desc">Answer a few quick questions...</div>
            <div className="actions">
              <Link
                href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/course-finder/welcome`}
              >
                <button className="btn btn-primary">Get started</button>
              </Link>
              <button
                className="btn btn-secondary"
                onClick={() => setIsOpen(!isOpen)}
              >
                Not now
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
