'use client';
import Image from 'next/image';
import Link from 'next/link';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ReadMoreReadLess from '@components/ReadMoreReadLess';
import GetStartedFloating from '@components/GetStartedFloating';

export default function Home() {
  const heroSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const testimonySliderSettings = {
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    centerPadding: '60px',
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          centerPadding: '0px',
          centerMode: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const projectsSliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };
  return (
    <main>
      {/* Hero Section */}
      <div className="section-hero">
        <div className="container">
          <Slider {...heroSliderSettings}>
            <div className="slide-wrap yoga-sutras">
              <div className="slide-image">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="/images/hero-slides/hero-slide-yoga-sutras-mobile.webp"
                  />
                  <Image
                    src="/images/hero-slides/hero-slide-yoga-sutras.webp"
                    alt="slide"
                    className="slide__image"
                    width={1160}
                    height={539}
                  />
                </picture>
              </div>
              <div className="slide-content">
                <div className="slide-title inline">
                  Yoga
                  <div>Sutras</div>
                </div>
                <div className="slide-text">
                  Discover the true heart of yoga & deepen your practice with
                  Gurudev
                </div>
                <div className="slide-action">
                  <Link href="https://event.us.artofliving.org/lp1/yoga-sutras-gurudev/">
                    <button
                      className="slide-action-btn primary-btn"
                      type="button"
                    >
                      Learn More
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="/images/hero-slides/hero-slide-mobile2.webp"
                  />
                  <Image
                    src="/images/hero-slides/hero-slide2.jpeg"
                    alt="slide"
                    className="slide__image"
                    width={1160}
                    height={539}
                  />
                </picture>
              </div>
              <div className="slide-content">
                <div className="slide-title">
                  Discover Gurudev's
                  <div>ancient secret to modern well-being</div>
                </div>
                <div className="slide-subtitle">
                  Art of Living Part 1 Course
                </div>
                <div className="slide-text">
                  Regain your calm with evidence-based practices in this
                  life-changing course
                </div>
                <div className="slide-action">
                  <Link href="https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&utm_medium=home&utm_content=banner&course_id=811569">
                    <button
                      className="slide-action-btn primary-btn"
                      type="button"
                    >
                      Learn More
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="/images/hero-slides/hero-slide-mobile3.webp"
                  />
                  <Image
                    src="/images/hero-slides/hero-slide3.jpeg"
                    alt="slide"
                    className="slide__image"
                    width={1160}
                    height={539}
                  />
                </picture>
              </div>
              <div className="slide-content">
                <div className="slide-title">
                  A voice for
                  <div>harmony in diversity</div>
                </div>
                <div className="slide-subtitle">
                  Gurudev Sri Sri Ravi Shankar
                </div>
                <div className="slide-text">
                  Spiritual leader, humanitarian, global ambassador of peace,
                  and founder of the Art of Living
                </div>
                <div className="slide-action">
                  <Link href="https://www.artofliving.org/us-en/gurudev">
                    <button
                      className="slide-action-btn primary-btn"
                      type="button"
                    >
                      Know More
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="/images/hero-slides/hero-slide-mobile4.webp"
                  />
                  <Image
                    src="/images/hero-slides/hero-slide4.jpeg"
                    alt="slide"
                    className="slide__image"
                    width={1160}
                    height={539}
                  />
                </picture>
              </div>
              <div className="slide-content">
                <div className="slide-title">
                  Questions about
                  <div>love, grief, dealing with change?</div>
                </div>
                <div className="slide-subtitle">
                  Get the National bestseller
                </div>
                <div className="slide-text">
                  Be inspired with new insights into how to deal with life’s
                  biggest challenges
                </div>
                <div className="slide-action">
                  <Link href="https://www.amazon.com/dp/B0BYZDCZDN?ref_=cm_sw_r_cp_ud_dp_7F5594EK8EMXRGPDZQ5D">
                    <button
                      className="slide-action-btn primary-btn"
                      type="button"
                    >
                      Know More
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                      </svg>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="section-gurudev">
        <div className="container">
          <div className="subsection-gurudev-inspire">
            <div className="section-content">
              <div className="section-title">
                <strong>Gurudev</strong> Sri Sri Ravi Shankar
              </div>
              <div className="section-subtitle">Be Inspired</div>
              <div className="section-text">
                Founder of the Art of Living, Gurudev has transformed the lives
                of millions worldwide through meditation, yoga, and practical
                wisdom
              </div>
              <div className="section-action">
                <Link href="https://www.artofliving.org/us-en/gurudev">
                  <button
                    className="section-action-btn primary-btn bg-transparent border-black"
                    type="button"
                  >
                    Learn More
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
            <div className="section-image">
              <Image
                src="/images/gurudevn-transparent-bg.webp"
                alt="gurudev"
                className="section__image"
                width={500}
                height={457}
              />
            </div>
            <div className="section-action-mobile">
              <button
                className="section-action-btn primary-btn bg-transparent border-black"
                type="button"
              >
                Learn More
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="subsection-aol-courses">
            <div className="section-title">
              ART OF LIVING <strong>COURSES</strong>
            </div>
            <div className="section-subtitle">
              Powerful breathing techniques, meditation, and wisdom that can
              change your life
            </div>
            <div className="aol-courses-listing">
              <div className="course-item">
                <div className="course-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/sky-mobile.webp"
                    />
                    <Image
                      src="/images/home-courses-1.webp"
                      alt="gurudev"
                      className="shadow-md"
                      width={570}
                      height={550}
                    />
                  </picture>
                </div>
                <div className="course-info">
                  <div className="course-title font-lora">
                    Art of Living Part 1
                  </div>
                  <div className="course-text">
                    Discover SKY Breath Meditation, an evidence-based technique
                    that quickly reduces stress, balances emotions, and restores
                    calm
                  </div>
                  <div className="course-action">
                    <Link href="https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=811569">
                      <button
                        className="section-action-btn primary-btn bg-transparent border-black color-white"
                        type="button"
                      >
                        Learn More
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="course-item">
                <div className="course-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/sahaj-mobile.webp"
                    />
                    <Image
                      src="/images/home-courses-2.webp"
                      alt="gurudev"
                      className="shadow-medium"
                      width={570}
                      height={550}
                    />
                  </picture>
                </div>
                <div className="course-info">
                  <div className="course-title font-lora">
                    Sahaj Samadhi Meditation
                  </div>
                  <div className="course-text">
                    Learn how to let go of worries and enjoy deep rest with this
                    practical, effective, and effortless meditation practice
                  </div>
                  <div className="course-action">
                    <Link href="https://event.us.artofliving.org/us-en/sahajsamadhi/?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=999649">
                      <button
                        className="section-action-btn primary-btn bg-transparent border-black color-white"
                        type="button"
                      >
                        Learn More
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="course-item">
                <div className="course-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/advance-course-mobile.webp"
                    />
                    <Image
                      src="/images/home-courses-3.webp"
                      alt="gurudev"
                      className="shadow-medium"
                      width={570}
                      height={550}
                    />
                  </picture>
                </div>
                <div className="course-info">
                  <div className="course-title font-lora">
                    Art of Living Part 2
                  </div>
                  <div className="course-text">
                    Unplug from the world for a few days to give yourself a true
                    vacation for mind, body, and spirit with a silent retreat
                  </div>
                  <div className="course-action">
                    <Link href="https://event.us.artofliving.org/us-en/artoflivingpart2?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=811570">
                      <button
                        className="section-action-btn primary-btn bg-transparent border-black color-white"
                        type="button"
                      >
                        Learn More
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="course-item">
                <div className="course-image">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="/images/sri-sri-yoga-mobile.webp"
                    />
                    <Image
                      src="/images/home-courses-4.webp"
                      alt="gurudev"
                      className="shadow-medium"
                      width={570}
                      height={550}
                    />
                  </picture>
                </div>
                <div className="course-info">
                  <div className="course-title font-lora">Sri Sri Yoga</div>
                  <div className="course-text">
                    Improve your flexibility and nourish your spirit with this
                    deeply relaxing style of yoga and its wealth of mind-body
                    benefits
                  </div>
                  <div className="course-action">
                    <Link
                      href={`${process.env.NEXT_PUBLIC_AUTH_APP_URL}/us-en/lp/online-foundation-program?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=1004431&_gl=1*123h937*_gcl_au*MjExNDEwMzk2OS4xNzI1NDYxNjc4*_ga*MTQ2NzYyMjU4My4xNjkzNzQ1MTg0*_ga_53SWQFSBV0*MTczMTA2NzkyMC4yNzYuMS4xNzMxMDY4NTUwLjU5LjAuMA..`}
                    >
                      <button
                        className="section-action-btn primary-btn bg-transparent border-black color-white"
                        type="button"
                      >
                        Learn More
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                        </svg>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-home-testimonies">
        <div className="container">
          <div className="section-title">
            Transforming Lives through Ancient Meditation Techniques
          </div>
          <Slider {...testimonySliderSettings}>
            <div className="slide-wrap">
              <div className="slide-title">
                <i>Come to Work in a Better Mindset</i>
              </div>
              <div className="slide-text">
                <ReadMoreReadLess
                  text="I was always interested in meditation and I had never meditated
                before. It was always something that was sort of elusive but
                Ithought that I maybe never be capable of it. I thought that
                maybe my mind moved too quickly or I wasn't focused enough.
                Directly after the course and since then... just this
                understanding that this moment in the now is to be appreciated
                and enjoyed... and that released a lot of stress for meWhen I
                come to work having already centered myself, it's way easier to
                plan... delegate tasks to other people, or work with other
                people. Now I come to work in a much better mindset, and that in
                turn translates into the quality of work and the way I deal with
                people at work. Just the way I process emotions, thoughts, and
                feelings is different from before."
                  maxCharacterCount={112}
                />
              </div>
              <div className="slide-author-info">
                <div className="author-image">
                  <Image
                    src="/images/testimonial-1.webp"
                    alt="gurudev"
                    className="shadow-medium"
                    width={55}
                    height={55}
                  />
                </div>
                <div className="author-content">
                  <div className="author-name">Dan J.</div>
                  <div className="author-position">Chef Asheville, NC</div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-title">
                <i>Great insights and practical tools</i>
              </div>
              <div className="slide-text">
                <ReadMoreReadLess
                  text="I joined the Art of Living Part 1 Course after an introductory
                meet up at the Art of Living Center. It felt like it could be a
                great program for me, and before I could change my mind, I
                signed up for it. If I had waited until later to join, I would
                have missed out on a truly life transforming experience. The
                program gave me great insights and practical tools to dealing
                with life's stressful situations. I met some really good friends
                that I still stay in contact with."
                  maxCharacterCount={99}
                />
              </div>
              <div className="slide-author-info">
                <div className="author-image">
                  <Image
                    src="/images/testimonial-2.webp"
                    alt="gurudev"
                    className="shadow-medium"
                    width={55}
                    height={55}
                  />
                </div>
                <div className="author-content">
                  <div className="author-name">Tiffany Guynes</div>
                  <div className="author-position">
                    Chef & private caterer, Austin, TX
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-title">
                <i>The best financial investment I have made in myself</i>
              </div>
              <div className="slide-text">
                <ReadMoreReadLess
                  text="I was at the height of my career, with a high profile job and a wonderful family. But stress was taking a toll on my physical and mental health – and I was relying on antibiotics and pain medicine regularly. Practicing SKY Meditation has reduced my stress levels and improved my immune system dramatically. I haven’t needed medication for 4 years now! This course is the best financial investment I have made in myself"
                  maxCharacterCount={96}
                />
              </div>
              <div className="slide-author-info">
                <div className="author-image">
                  <Image
                    src="/images/testimonial-4.webp"
                    alt="gurudev"
                    className="shadow-medium"
                    width={55}
                    height={55}
                  />
                </div>
                <div className="author-content">
                  <div className="author-name">Uma Vishwanath</div>
                  <div className="author-position">
                    Team lead in a high-tech company, Lexington, MA
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-title">
                <i>Much more confident and happier, despite the struggles</i>
              </div>
              <div className="slide-text">
                <ReadMoreReadLess
                  text="Before the Art of Living Part 1 Course, I was on medication for panic and anxiety attacks. After the first day of the Art of Living Part 1 Course, I experienced a full night’s sleep, which was rare for me. The next morning was one of the happiest I had ever felt. I’ve been constant with my SKY meditation practice for the last two years - it makes me feel so alive. My friends and family tell me that I am much more confident and happier. Despite the struggles last year - my grandfather passed away, I lost my job and I went through a divorce - I still did not have a panic or anxiety attack. I feel grateful for this inner strength because I don’t know where I would be without it."
                  maxCharacterCount={106}
                />
              </div>
              <div className="slide-author-info">
                <div className="author-image">
                  <Image
                    src="/images/testimonial-3.webp"
                    alt="gurudev"
                    className="shadow-medium"
                    width={55}
                    height={55}
                  />
                </div>
                <div className="author-content">
                  <div className="author-name">Robert Delmont</div>
                  <div className="author-position">
                    Elementary music teacher, Boston, MA
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="section-media-words">
        <div className="container">
          <div className="section-title">
            ART OF LIVING <strong>AS SEEN IN</strong>
          </div>
          <div className="media-houses">
            <div className="media-item">
              <div className="media-logo">
                <Image
                  src="/images/HomeChannelreviewImg1.webp"
                  alt="CNN"
                  className="media-logo-img"
                  width={80}
                  height={38}
                />
              </div>
              <div className="media-text">
                <i>"Life Changing"</i>
              </div>
            </div>
            <div className="media-item">
              <div className="media-logo">
                <Image
                  src="/images/HomeChannelreviewImg2.webp"
                  alt="CNN"
                  className="media-logo-img"
                  width={90}
                  height={29}
                />
              </div>
              <div className="media-text">
                <i>
                  "May be the fastest growing spiritual practice on the planet"
                </i>
              </div>
            </div>
            <div className="media-item">
              <div className="media-logo">
                <Image
                  src="/images/HomeChannelreviewImg3.webp"
                  alt="CNN"
                  className="media-logo-img"
                  width={100}
                  height={100}
                />
              </div>
              <div className="media-text">
                <i>"Shows promise in providing relief for depression"</i>
              </div>
            </div>
            <div className="media-item">
              <div className="media-logo">
                <Image
                  src="/images/HomeChannelreviewImg4.webp"
                  alt="CNN"
                  className="media-logo-img"
                  width={90}
                  height={63}
                />
              </div>
              <div className="media-text">
                <i>"Like Fresh air to millions"</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Servoce Projects */}
      <div className="section-home-service-projects">
        <div className="container">
          <div className="section-title">
            ART OF LIVING <strong>SERVICE PROJECTS</strong>
          </div>
          <div className="section-subtitle">
            Empowering individuals and transforming communities through various
            social projects
          </div>
          <Slider {...projectsSliderSettings}>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-rehabiliation-imge.webp"
                  alt="rehabiliation"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#2c6a8b' }}>
                  Prison <span className="text-italic">Rehabilitation</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">800,000+</div>
                  <div className="slide-text">
                    prison inmates & staff have been transformed through these
                    programs in over 65 countries
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-plant-trees-image.webp"
                  alt="plant trees"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#336c00' }}>
                  Tree <span className="text-italic">Plantations</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">81 millions</div>
                  <div className="slide-text">
                    trees planted in 36 countries
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-truma.webp"
                  alt="rehabiliation"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#1554AF' }}>
                  Trauma Relief{' '}
                  <span className="text-italic">for Veterans & Families</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">8,000+</div>
                  <div className="slide-text">
                    veterans & their families have experienced this program
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-stress-free.webp"
                  alt="rehabiliation"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#A84000' }}>
                  Stress-free{' '}
                  <span className="text-italic">College Campuses</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">275,000</div>
                  <div className="slide-text">
                    students across 110+ colleges and universities have taken
                    meditation and breathwork courses
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-peace.webp"
                  alt="rehabiliation"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#0064B7' }}>
                  Working for peace{' '}
                  <span className="text-italic">In our cities</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">91%</div>
                  <div className="slide-text">
                    Increase in the ability to remain calm in challenging
                    situations. The program has brought together at-risk youth
                    and law enforcement to build trust and peace
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-wrap">
              <div className="slide-image">
                <Image
                  src="/images/sp-empowering.webp"
                  alt="rehabiliation"
                  className="sp-slide-image"
                  width={580}
                  height={420}
                />
              </div>
              <div className="slide-content">
                <div className="slide-title" style={{ color: '#5F2EB0' }}>
                  Empowering <span className="text-italic">Children K-12</span>
                </div>
                <div className="slide-text-info">
                  <div className="slide-text-count">350,000</div>
                  <div className="slide-text">
                    Students across 225 schools have received the tools and
                    mentorship needed to manage their minds and emotions.
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <GetStartedFloating></GetStartedFloating>
    </main>
  );
}
