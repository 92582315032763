// components/ReadMoreReadLess.js
import { useState } from 'react';

interface ReadMoreReadLessProps {
  text: string;
  maxCharacterCount: number;
}

const ReadMoreReadLess: React.FC<ReadMoreReadLessProps> = ({
  text,
  maxCharacterCount,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <p>
        {isExpanded ? text : `${text.slice(0, maxCharacterCount)}...`}
        <span onClick={toggleReadMore} className="readMore-link">
          {isExpanded ? ' Read Less' : ' Read More'}
        </span>
      </p>
    </div>
  );
};

export default ReadMoreReadLess;
